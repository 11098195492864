<template>
  <div>
    <div class="flex flex-col mb-2">
      <PageHeader>
        <div class="text-gray-900 text-2xl font-semibold">
          Gestion des articles
        </div>
      </PageHeader>
      <div class="flex px-10 pt-6">
        <div
          class="flex-1 flex shadow flex-col text-center justify-center px-8 py-4 bg-white rounded mr-4"
        >
          <skeleton-list :row="3" v-if="loadingStat" />
          <div v-if="!loadingStat" class="text-2xl font-bold">
            {{ statistique.count_stock }}
          </div>
          <div v-if="!loadingStat" class="text-blue-500 mt-2">
            Nombre de produits
          </div>
        </div>

        <div
          class="flex-1 flex text-center shadow flex-col justify-center px-8 py-4 bg-white rounded mr-4"
        >
          <skeleton-list :row="3" v-if="loadingStat" />
          <div v-if="!loadingStat" class="text-2xl font-bold">
            {{ statistique.total_stock_chifffre | moneyFilter }} {{user.currency_code}}
          </div>
          <div v-if="!loadingStat" class="text-green-500 mt-2">
            Total des produits
          </div>
        </div>

        <div
          class="flex-1 flex text-center shadow flex-col justify-center px-8 py-4 bg-white rounded mr-4"
        >
          <skeleton-list :row="3" v-if="loadingStat" />
          <div v-if="!loadingStat" class="text-2xl font-bold">
            {{ statistique.count_sold_out }}
          </div>
          <div v-if="!loadingStat" class="text-red-500 mt-2">
            Articles epuissés
          </div>
        </div>
        <div
          class="flex-1 flex text-center shadow flex-col justify-center px-8 py-4 bg-white rounded"
        >
          <skeleton-list :row="3" v-if="loadingStat" />
          <div v-if="!loadingStat" class="text-2xl font-bold">
            {{ statistique.count_on_stock }}
          </div>
          <div v-if="!loadingStat" class="text-yellow-500 mt-2">
            Articles en surstock
          </div>
        </div>
      </div>
      <div class="px-10">
        <div class="flex flex-col mt-6 bg-white border px-6 pt-6">
          <div class="flex flex-row mb-10">
            <router-link class="mr-4" to="create">
              <el-button type="primary" icon="el-icon-circle-plus"
                >Créer
              </el-button>
            </router-link>
            <el-input
              class="mr-4"
              placeholder="Recherche un article"
              v-model="listQuery.name"
              @keydown.native.enter="fetchArticles()"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <!--   <el-select
            v-model="listQuery.uom"
            @change="fetchArticles()"
            class="mr-4"
            placeholder="Unite"
            clearable
          >
            <el-option
              v-for="item in uoms"
              :key="item.id"
              :label="`${item.libelle} - ${item.name}`"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
            <el-select
              class="mr-4"
              style="width: 30%"
              placeholder="Categorie"
              v-model="listQuery.category"
              @change="fetchArticles()"
              clearable
            >
              <el-option
                v-for="(item, index) in categories"
                :key="index"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 30%"
              class="filter-item mr-1"
              v-model="listQuery.warehouseCode"
              placeholder="Entrepôt "
              @clear="clearWarehouse()"
              filterable
              clearable
              @change="fetchArticles()"
            >
              <el-option
                v-for="item in warehouses"
                :key="item.id"
                :label="`${item.name}`"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-button>Exporter</el-button>
          </div>
          <el-tabs v-model="activeName" @tab-click="handleTab">
            <el-tab-pane label="Tous" name="ALL"></el-tab-pane>
            <el-tab-pane label="Actifs" name="ACTIF"></el-tab-pane>
            <el-tab-pane label="Inactifs" name="INACTIF"></el-tab-pane>
            <!-- <el-tab-pane label="Epuisés" name="STOCK-OUT"></el-tab-pane>
        <el-tab-pane label="Surstock" name="ON-STOCK"></el-tab-pane> -->
          </el-tabs>
          <skeleton-list :row="8" v-if="listLoading" />
          <el-table :data="items" v-else class="mt-3" style="width: 100%">
            <el-table-column label="" width="80">
              <template slot-scope="{ row }">
                <el-switch
                  size="medium"
                  v-model="row.active"
                  @change="handleActive(row.article_id)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="sku" label="N°ARTICLE" width="150">
              <template slot-scope="{ row }">
                <router-link :to="`fiche/${row.article_id}`">
                  <span class="text-blue-400">{{ row.sku }}</span>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="category_name"
              label="CATEGORIE"
              width="210"
              align="left"
            >
            </el-table-column>
            <!--     <el-table-column prop="uom" label="UNITE" width="85" align="center">
        </el-table-column> -->
            <el-table-column label="STATUT" align="center" width="100">
              <template slot-scope="{ row }">
                <Status :name="row.active" />
              </template>
            </el-table-column>
            <el-table-column
              prop="article_name"
              label="DESIGNATION"
              min-width="150"
            >
            </el-table-column>

            <el-table-column
              prop="selling_price"
              label="PRIX DE VENTE"
              align="right"
              width="160"
            >
              <template slot-scope="{ row }">
                <span>{{ row.selling_price | moneyFilter }} {{user.currency_code}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="average_cost"
              label="PRIX D'ACHAT"
              width="160"
              align="right"
            >
              <template slot-scope="{ row }">
                <span>{{ row.average_cost | moneyFilter }} {{user.currency_code}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="stock_on_hand"
              width="150"
              label="STOCK DISP."
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row.stock_on_hand }}</span>
              </template>
            </el-table-column>

            <el-table-column label="" align="right" width="80">
              <template slot-scope="{ row }">
                <el-dropdown @command="handleCommand" trigger="click">
                  <el-button size="mini" icon="el-icon-menu" circle></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{ stock: row }">
                      <i class="el-icon-files"></i>
                      Afficher l'etat des stocks</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ price: row }">
                      <i class="el-icon-money"></i>
                      Modifier les prix</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ update: row }">
                      <i class="el-icon-edit"></i>
                      Modifier l'article</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ get: row }">
                      <i class="el-icon-document"></i>
                      Details de l'article</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>

          <div class="flex justify-end">
            <pagination
              v-show="listQuery.total > 0"
              :total="listQuery.total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.size"
              @pagination="fetchArticles()"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="LIEUX DE STOCKAGE"
      :visible.sync="dialogVisible"
      width="38%"
      top="4vh"
    >
      <stock :isUpdate="true" :articleId="articleId" class="mt-2" />
    </el-dialog>

    <el-dialog
      title="MODIFIER LES PRIX"
      :visible.sync="dialogUpdatePriceVisible"
      width="20%"
      top="4vh"
    >
      <el-form :model="updateArticlePrice" :label-position="labelPosition">
        <el-form-item label="Prix de vente ">
          <el-input-number
            v-model="updateArticlePrice.price_sale"
            :min="1"
            :controls="false"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Prix d'achat ">
          <el-input-number
            v-model="updateArticlePrice.price_purchase"
            :controls="false"
            :min="1"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUpdatePriceVisible = false">Annuler</el-button>
        <el-button
          type="primary"
          @click="handleUpdateItem"
          :loading="loadingPrice"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCategories,
  getArticles,
  getStatistique,
  updatePriceArticle,
  getUoms,
  activeArticle,
} from "@/api/article";

import { fetchWarehouse } from "@/api/stock";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseTime } from "@/utils";
import Stock from "./stock.vue";

import PageHeader from "@/components/PageHeader";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";

export default {
  name: "Article",
  components: { Pagination, Stock, Status, PageHeader, SkeletonList },
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      activeName: "ALL",
      labelPosition: "top",
      categories: [],
      items: [],
      uoms: [],
      warehouses: [],
      articleId: "",
      loadingPrice: false,
      loadingStat: false,
      dialogVisible: false,
      dialogUpdatePriceVisible: false,
      listLoading: false,
      updateArticlePrice: {
        article_id: "",
        price_sale: 0,
        price_purchase: 0,
      },
      statistique: {
        count_on_stock: 0,
        count_sold_out: 0,
        count_stock: 0,
        total_stock_chifffre: 0,
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 50,
        sort: "asc",
        sortDir: "name",
        warehouseCode: undefined,
        name: undefined,
        category: undefined,
        uom: undefined,
        active: undefined,
      },
    };
  },
  mounted() {
    this.fetchCategories();
    this.fetchArticles();
    this.fetchStat();
    this.fetchUoms();
    this.fetchWarehouse();
  },
    computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    handleEdit(row) {
      this.$emit("article-update", row);
    },
    async fetchCategories() {
      await getCategories()
        .then((res) => {
          this.categories = res.data;
          // console.log(this.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchUoms() {
      await getUoms()
        .then((res) => {
          this.uoms = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleActive(numero) {
      await activeArticle(numero)
        .then(() => {
          this.$message({
            message: "Le statut de l'article a changé !",
            type: "success",
          });
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    async fetchArticles() {
      this.listLoading = true;
      if (this.listQuery.name === "") {
        this.listQuery.name = undefined;
      }
      if (this.listQuery.category === "") {
        this.listQuery.category = undefined;
      }

      await getArticles(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchStat() {
      this.loadingStat = true;
      await getStatistique()
        .then((res) => {
          setTimeout(() => {
            this.statistique = { ...res.data };
            this.loadingStat = false;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleTab(obj) {
      const name = obj.name;
      console.log(name);
      switch (name) {
        case "ACTIF":
          this.listQuery.active = true;
          break;

        case "INACTIF":
          this.listQuery.active = false;
          break;

        case "STOCK-OUT":
          break;
        case "ALL":
          this.listQuery.active = undefined;
          break;
        case "ON-STOCK":
          break;
      }
      this.fetchArticles();
    },
    handleStock(articleId) {
      this.articleId = articleId;
      this.dialogVisible = true;
    },
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },
    handleDialogVisiblePrice(item) {
      this.updateArticlePrice.article_id = item.article_id;
      this.updateArticlePrice.price_sale = item.selling_price;
      this.updateArticlePrice.price_purchase = item.average_cost;
      this.dialogUpdatePriceVisible = true;
    },
    async handleUpdateItem() {
      this.loadingPrice = true;
      await updatePriceArticle(this.updateArticlePrice)
        .then(() => {
          setTimeout(() => {
            const item = this.items.find(
              (el) => el.article_id === this.updateArticlePrice.article_id
            );
            item.selling_price = this.updateArticlePrice.price_sale;
            item.average_cost = this.updateArticlePrice.price_purchase;
            this.loadingPrice = false;
            this.dialogUpdatePriceVisible = false;
          }, 1000);
        })
        .catch((error) => {
          this.loadingPrice = false;
          console.log(error);
        });
    },
    handleCommand(e) {
      if (e.update) {
        this.$router.push(`update/${e.update.article_id}`);
      }
      if (e.stock) {
        this.handleStock(e.stock.article_id);
      }
      if (e.price) {
        this.handleDialogVisiblePrice(e.price);
      }
      if (e.get) {
        this.$router.push(`fiche/${e.get.article_id}`);
      }
    },
  },
};
</script>

<style></style>
